import type { FC } from 'react';
import * as Sentry from '@sentry/nextjs';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';

import { isSearchEngineBot, locationProps, token } from '@grid-is/browser-utils';
import { useDocumentIdFromRouter } from '@grid-is/custom-hooks';
import { getConfig } from '@grid-is/environment-config';

import { type Document, getDocument } from '@/api/document';
import { getMyUser } from '@/api/user';
import { DocumentView } from '@/components/Document/DocumentView';
import { useAuth } from '@/utils/auth';

import { maybeReportToSentry } from '../../../utils/errorHandling';

const config = getConfig();

const DEFAULT_BG_COLOR = 'ffffff';

function getBackgroundColor (doc: Document | undefined) {
  try {
    const body = JSON.parse(doc?.body || '{}');
    const backgroundColor = body.attr.design.backgroundColor || DEFAULT_BG_COLOR;
    return backgroundColor.startsWith('#') ? backgroundColor.slice(1) : backgroundColor;
  }
  catch (e) {
    return DEFAULT_BG_COLOR;
  }
}

type PageProps = InferGetServerSidePropsType<typeof getServerSideProps> &
{
  additionalAnalytics: Record<string, any>,
  setAdditionalAnalytics: (analytics: Record<string, any>) => void,
};

const DocumentPage: FC<PageProps> = ({ documentFromServer, userFromServer, additionalAnalytics, setAdditionalAnalytics }) => {
  const { user } = useAuth({ fallbackData: userFromServer });
  const documentId = useDocumentIdFromRouter();
  const backgroundColor = getBackgroundColor(documentFromServer?.document);
  const isPublic = documentFromServer?.document?.view_access === 'public';
  return (
    <>
      {documentFromServer &&
        <Head>
          <title>{documentFromServer.title} - Calculator Studio</title>
          <meta name="title" content={documentFromServer.title} key="metaTitle" />
          <meta name="description" content={documentFromServer.description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={documentFromServer.path} />
          <meta property="og:title" content={documentFromServer.title} key="ogTitle" />
          <meta property="og:image" content={`${documentFromServer.thumbnailUrl}?ar=1.9:1&fit=crop&crop=top&bg=${backgroundColor}`} />
          <meta property="og:description" content={documentFromServer.description} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={documentFromServer.path} />
          <meta property="twitter:title" content={documentFromServer.title} key="twitterTitle" />
          <meta property="twitter:image" content={`${documentFromServer.thumbnailUrl}?ar=2:1&fit=crop&crop=top&bg=${backgroundColor}`} />
          <meta property="twitter:description" content={documentFromServer.description} />`;
          <link rel="canonical" href={documentFromServer.path} />
          <meta name="robots" content={isPublic ? 'index, follow' : 'noindex'} />
        </Head>}
      <DocumentView
        mode="view"
        user={user}
        shouldServerSideRender={documentFromServer?.shouldServerSideRender}
        documentFromServer={documentFromServer}
        additionalAnalytics={additionalAnalytics}
        setAdditionalAnalytics={setAdditionalAnalytics}
        key={documentId}
        embed={false}
        />
    </>
  );
};

function shouldServerSideRender (ctx: GetServerSidePropsContext) {
  try {
    if (ctx.query.ssr === '1') {
      // allow developers to play around with server side rendering, by visiting a document with the
      // query parameter ?ssr=1
      return true;
    }
    const isLoggedIn = !!token.get(ctx);
    return !isLoggedIn &&
      config?.DYNAMIC_RENDERING &&
      isSearchEngineBot(ctx.req?.headers['user-agent']);
  }
  catch (e) {
    Sentry.captureException(e);
    return false;
  }
}

// XXX when we get good experience with having current user from server,
// we should consider having it performed top level (e.g. _app)
function getCurrentUser (ctx: GetServerSidePropsContext) {
  const authToken = token.get(ctx);
  if (!authToken) {
    return null;
  }
  return getMyUser(authToken);
}

type DocumentFromServer = {
  title: string,
  description: string,
  path: string,
  thumbnailUrl: string,
  document?: Document,
  shouldServerSideRender?: boolean,
};

async function getDocumentMetaData (ctx: GetServerSidePropsContext): Promise<{ documentFromServer: DocumentFromServer, status: number }> {
  const { req, query } = ctx;
  const documentId = String(query.documentId || '');
  const documentUrl = `${locationProps(req).origin}/${query.user}/${documentId}`;
  try {
    const document = await getDocument(documentId, token.get(ctx), false);
    return {
      documentFromServer: {
        title: document.title,
        description: document.description,
        path: documentUrl,
        thumbnailUrl: document.thumbnail_url || '',
        document,
        shouldServerSideRender: shouldServerSideRender(ctx),
      },
      status: 200,
    };
  }
  catch (e: any) {
    if ([ 400, 404 ].includes(e.status)) {
      return {
        documentFromServer: {
          title: 'Document Not Found',
          description: 'Not Found',
          path: documentUrl,
          thumbnailUrl: 'https://grid.is/img/share-blank.png',
        },
        status: e.status,
      };
    }
    else {
      maybeReportToSentry(e);
    }
    return {
      documentFromServer: {
        title: 'Document Inaccessible',
        description: '',
        path: documentUrl,
        thumbnailUrl: 'https://grid.is/img/share-blank.png',
      },
      status: e.status,
    };
  }
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  if (ctx.req?.headers['user-agent'] === 'GRID e2e tests') {
    return { props: {} };
  }
  try {
    const [ docMetadataResponse, userFromServer ] = await Promise.all([ getDocumentMetaData(ctx), getCurrentUser(ctx) ]);
    const { documentFromServer, status } = docMetadataResponse;
    const isPublic = documentFromServer?.document?.view_access === 'public';
    if (ctx.res) {
      if (!isPublic) {
        ctx.res.setHeader('X-Robots-Tag', 'noindex');
      }
      ctx.res.statusCode = status;
    }
    return { props: { documentFromServer, userFromServer } };
  }
  catch (e: unknown) {
    maybeReportToSentry(e);
    return { props: {} };
  }
};

export default DocumentPage;
